@import "variable.scss";
.table {
  width: 100%;
  border: solid 1px $tableBorder;
  border-radius: 6px;
  overflow: auto;

  table {
    width: 100%;
  }

  thead,
  tfoot {
    background-color: $tableHeadBackground;
    padding: 5px;
  }

  tbody tr {
    border-bottom: solid 1px $tableBorder;
  }

  tbody tr:last-child {
    border-bottom: none;
  }

  th,
  td {
    text-align: justify;
    padding: 10px 15px;
  }

  td:last-child {
    text-align: right;
  }
}
